import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { EmptySpace } from "../../empty_space";

import Logo from "@images/logo/logo.png";

import styles from "./navbar.style.module.css";
import { Dropdown } from "./dropdown";

export const HeaderMaxWidthDropDown = () => {
  const [navHeight, setNavHeight] = useState("80px");
  const [navBgColor, setNavColor] = useState("white");
  // const [navFontColor, setNavFontColor] = useState("#e2e2e2");
  const navigate = useNavigate();

  const listenScrollEvent = () => {
    if (window.scrollY < 10) {
      // -------- intial scroll -----------
      setNavColor("white");
      setNavHeight("80px");
      // setNavFontColor("#e2e2e2");
    } else {
      //-------- after scroll ----------
      setNavColor("white");
      setNavHeight("90px");
      // setNavFontColor("#e2e2e2");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  return (
    <React.Fragment>
      <div
        style={{
          "--header-bg-color": navBgColor,
          height: navHeight,
          backgroundColor: navBgColor,
        }}
        className={`wrapper ${styles.headerWrapper}`}
      >
        <div className={`container ${styles.leftCenterRightContainer}`}>
          <div className={styles.leftInfo}>
            <img
              className={styles.logo}
              src={Logo}
              alt="logo"
              onClick={() => navigate("/")}
            />
            <div className={styles.companyName} onClick={() => navigate("/")}>
              <p>Skynoveau</p>
              <p>Technology</p>
            </div>
          </div>

          <Dropdown />

          <LastMenuButton />
        </div>
      </div>

      {/* empty space  */}
      <EmptySpace bgColor={navBgColor} height={navHeight} />
    </React.Fragment>
  );
};

const LastMenuButton = () => {
  const navigate = useNavigate();
  return (
    <div
      className={styles.lastMenuButton}
      onClick={() => navigate("/plans-pricing")}
    >
      Pricing
    </div>
  );
};
