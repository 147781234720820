import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { Header } from "@components/navbar";
import { Footer } from "@components/footer";
import { SimpleLoader as Loader } from "@components/loaders/simple_loader";
import { EntryProvider } from "@components/entry_provider";
import { WhataApp } from "@components/whatsapp";
import SuspenseLoader from "@components/lazy_loaders/suspense_lazy_loader";

import "@common/theme.css";
import "@common/global.css";
import "@common/font/font.css";
import "@common/font/text.css";
import "@common/font/title.css";
import "@common/global.override.css";

// Lazy-loaded components
const NotFound = SuspenseLoader(
  React.lazy(() => import("@components/page_not_found"))
);
const Home = SuspenseLoader(React.lazy(() => import("@pages/home")));
const About = SuspenseLoader(React.lazy(() => import("@pages/about")));
const Contact = SuspenseLoader(React.lazy(() => import("@pages/contact")));
const Services = SuspenseLoader(React.lazy(() => import("@pages/service")));
const Pricing = SuspenseLoader(React.lazy(() => import("@pages/pricing")));
const TermsConditions = SuspenseLoader(
  React.lazy(() => import("@pages/policies/terms.condition.jsx"))
);
const Privacy = SuspenseLoader(
  React.lazy(() => import("@pages/policies/privacy.jsx"))
);
const RefundCancellation = SuspenseLoader(
  React.lazy(() => import("@pages/policies/refund.cancellation.jsx"))
);

function App() {
  const location = useLocation();
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    setIsLoader(true);

    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <EntryProvider>
      {isLoader && <Loader />}
      {!isLoader && (
        <React.Fragment>
          <Header />
          <WhataApp />

          {/* routes */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/plans-pricing" element={<Pricing />} />
            <Route path="/terms-condition" element={<TermsConditions />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route
              path="/refund-cancellation-policy"
              element={<RefundCancellation />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>

          <Footer />
        </React.Fragment>
      )}
    </EntryProvider>
  );
}

export default App;
