import React from "react";
import { useNavigate } from "react-router-dom";

import { FOOTER_MENU_LINKS, SOCIAL_MEDIA_LINKS } from "./constant";
import { ImageLazyLoader } from "@components/lazy_loaders/image_lazy_loader";
import { AOS_ANIMATION } from "@common/aos_animation";

import WhiteLogo from "@images/logo/white-logo.png";

import styles from "./footer.style.module.css";

export const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className={`wrapper ${styles.footerWrapper}`}>
      <div className={`container ${styles.footerContainer}`}>
        <div className={styles.logoLinkcontainer}>
          {/* ---------- logo section ----------- */}
          <div className={styles.logoContainerInfo}>
            <ImageLazyLoader
              src={WhiteLogo}
              className={styles.logo}
              data-aos={AOS_ANIMATION.FADE_UP.TYPE}
              data-aos-duration={AOS_ANIMATION.FADE_UP.DURATION}
            />
            <div
              className={styles.companyName}
              data-aos={AOS_ANIMATION.FADE_UP.TYPE}
              data-aos-duration={AOS_ANIMATION.FADE_UP.DURATION}
            >
              Skynoveau Technology
            </div>
            <div
              className={styles.slogan}
              data-aos={AOS_ANIMATION.FADE_UP.TYPE}
              data-aos-duration={AOS_ANIMATION.FADE_UP.DURATION}
            >
              The making wonders
            </div>
            <div className={styles.mediaIconContainer}>
              {SOCIAL_MEDIA_LINKS.map((item, index) => {
                return (
                  <a
                    href={item.path}
                    target="_blank"
                    key={index}
                    data-aos={AOS_ANIMATION.FADE_UP.TYPE}
                    data-aos-duration={AOS_ANIMATION.FADE_UP.DURATION}
                    rel="noreferrer"
                  >
                    {item.icon}
                  </a>
                );
              })}
            </div>
          </div>

          {/* ---------- link sections ---------- */}
          {FOOTER_MENU_LINKS.map((item, index) => {
            return (
              <div className={styles.menuLinksContainer} key={index}>
                <div className={styles.title}>{item.title}</div>
                {item["links"].map((data, index) => {
                  return (
                    <div
                      className={styles.linkInfo}
                      key={index}
                      onClick={() => data?.path && navigate(data.path)}
                      data-aos={AOS_ANIMATION.FADE_UP.TYPE}
                      data-aos-duration={AOS_ANIMATION.FADE_UP.DURATION}
                    >
                      {data.name}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>

        {/* --------- copy rights ---------- */}
        <div className={styles.copyRightsContainer}>
          <div className={styles.leftInfo}>
            All rights reserved by Skynoveau technology.
          </div>
          <div className={styles.rightInfo}></div>
        </div>
      </div>
    </div>
  );
};
